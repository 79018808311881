import './ClueBoard.css';

import { Component } from "react";
// @ts-ignore: Deal with import issues later
import Card from "./Card";
import { CardData } from './GameData';

interface State {
    shownURL: string;
}

interface Props {
    cards: CardData[];

    rotate: any;
    changeSelect: any;
}

export default class ClueBoard extends Component<Props, State> {
    render() {
        let itemList = this.props.cards.map((item: CardData, index: number) => {
            return <div key={item.id} className="card"><Card cardData={item} rotate={(rotationDirection: number) => this.props.rotate(index, rotationDirection)} changeSelect={(e: any) => this.props.changeSelect(index, e)} /></div>
        })

        return (
            <div className="row">
                {itemList}
            </div>
        );
    }
}