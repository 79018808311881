import React, { Component } from 'react';
import './Card.css';
import { CardData, RotationDirection } from './GameData';

interface State {
}

interface Props {
    rotate: any;
    changeSelect: any;

    cardData: CardData;
}

export default class Card extends Component<Props, State> {
    render() {
        var outerClasses = "Card";
        if (this.props.cardData.selected) {
            outerClasses = "Card selected"
        }

        return (
            <div className={outerClasses} onClick={() => { if (this.props.cardData.selectEnabled) { this.props.changeSelect() } }}>

                <div className="up">{this.props.cardData.north} </div>
                <div className="right">{this.props.cardData.east} </div>
                <div className="down">{this.props.cardData.south} </div>
                <div className="left">{this.props.cardData.west} </div>

                {this.props.cardData.rotateEnabled ?
                    <>
                        <button onClick={() => this.props.rotate(RotationDirection.CounterClockwise)} className="counter-clockwise-rotate">⤵️</button>
                        <button onClick={() => this.props.rotate(RotationDirection.Clockwise)} className="clockwise-rotate">⤵️</button></> : null
                }
            </div>
        );
    }
}
