import './App.css';

// @ts-ignore: Unreachable code error
import { Create } from './Create';
// @ts-ignore: Unreachable code error
import { Solve } from './Solve';
// @ts-ignore: Unreachable code error
import { Help } from './Help';
import { Component } from 'react';

import { Route, Routes, BrowserRouter as Router, Link } from "react-router-dom";
import React from 'react';
//import { DndProvider } from 'react-dnd';
//import { HTML5Backend } from 'react-dnd-html5-backend';

export class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          Trifolium
          <Link to="/h" className="btn btn-primary help">
            ❔
          </Link>
        </header>
        <Routes>
          <Route path="/" element={<Create />} />
          <Route path="/s" element={<Solve />} />
          <Route path="/h" element={<Help />} />
        </Routes>
      </div>
    );
  }
}

const AppWrapper = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};

export default AppWrapper;

  //<DndProvider backend={HTML5Backend}>
  // </DndProvider>
/*
          <button className="help">
          ❔
        </button>
        <Popup>
          <span> Modal content </span>
        </Popup>
        */