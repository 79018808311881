import { Component } from 'react';
import './Board.css';

// @ts-ignore: Deal with import issues later
import Card from "./Card";
import { CardData, GameMode, HintData } from './GameData';

interface HintState {

}

interface HintProps {
    mode: GameMode;
    hint: string;

    updateHint: any;
}

class Hint extends Component<HintProps, HintState> {
    constructor(props: HintProps) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e: any) {
        this.props.updateHint(e.target.value);
    }

    render() {
        if (this.props.mode === GameMode.Create) {
            return <input onChange={this.handleChange} />
        } else {
            return <div>{this.props.hint}</div>
        }
    }

}

interface BoardState {

}

interface BoardProps {
    hints: HintData;
    cards: CardData[];
    mode: GameMode;

    getHint: any;

    changeSelect: any;
    rotate: any;
}

export default class Board extends Component<BoardProps, BoardState> {
    render() {
        var hints = this.props.hints;
        if (this.props.hints === undefined) {
            hints = HintData.empty();
        }

        var cards = this.props.cards;
        if (this.props.cards === undefined) {
            cards = [];
        }

        return (
            <div className="Board">
                <div className="empty"></div>
                <div className="topper"><Hint mode={this.props.mode} hint={hints.hints[0]} updateHint={(e: any) => this.props.getHint(e, 0)} /></div>
                <div className="empty"></div>

                <div className="lefter">
                    <div className="lefterText"><Hint mode={this.props.mode} hint={hints.hints[3]} updateHint={(e: any) => this.props.getHint(e, 3)} /></div>
                </div>
                <div className="space upLeft"><Card cardData={cards[0]} changeSelect={(e: any) => this.props.changeSelect(0, e)} rotate={(rotationDirection: number) => this.props.rotate(0, rotationDirection)} /></div>
                <div className="space upRight"><Card cardData={cards[1]} changeSelect={(e: any) => this.props.changeSelect(1, e)} rotate={(rotationDirection: number) => this.props.rotate(1, rotationDirection)} /></div>
                <div className="righter">
                    <div className="righterText"><Hint mode={this.props.mode} hint={hints.hints[1]} updateHint={(e: any) => this.props.getHint(e, 1)} /></div>
                </div>
                <div className="space bottomLeft"><Card cardData={cards[2]} changeSelect={(e: any) => this.props.changeSelect(2, e)} rotate={(rotationDirection: number) => this.props.rotate(2, rotationDirection)} /></div>
                <div className="space bottomRight"><Card cardData={cards[3]} changeSelect={(e: any) => this.props.changeSelect(3, e)} rotate={(rotationDirection: number) => this.props.rotate(3, rotationDirection)} /></div>

                <div className="empty"></div>
                <div className="bottomer"><Hint mode={this.props.mode} hint={hints.hints[2]} updateHint={(e: any) => this.props.getHint(e, 2)} /></div>
                <div className="empty"></div>
            </div>
        );
    }
}