import './Solve.css';

// @ts-ignore: Deal with import issues later
import Board from './Board';
// @ts-ignore: Deal with import issues later
import ClueBoard from './ClueBoard';
import { Component } from 'react';
import { CardData, GameMode, RotationDirection, RoundData } from './GameData';
// @ts-ignore: Deal with import issues later
import { decode, getGameParam } from './Parameter';
import { shuffle } from './GameData';

interface State {
  data: RoundData

  tableCards: CardData[][]
  guessText: string
  guessCount: number
}

interface Props {
}

enum BoardType {
  Playboard = 0,
  Clueboard,
}

export class Solve extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    var puzzle = getGameParam()
    var data = decode(puzzle)

    var tableCards = [[new CardData("", "", "", ""), new CardData("", "", "", ""), new CardData("", "", "", ""), new CardData("", "", "", "")], shuffle(data.cardData)]

    for (var i = 0; i < tableCards[0].length; i++) {
      tableCards[0][i].selectEnabled = true
      tableCards[0][i].rotateEnabled = false
    }
    for (var j = 0; j < tableCards[1].length; j++) {
      tableCards[1][j].selectEnabled = true
      tableCards[1][j].rotateEnabled = true
    }

    this.state = {
      data: data,

      tableCards: tableCards,

      guessText: "",
      guessCount: 0,
    }
  }

  updateCardData(index: number, tableCards: CardData[][], rotationDirection: RotationDirection, boardType: BoardType) {
    if (rotationDirection === RotationDirection.Clockwise) {
      tableCards[boardType.valueOf()][index].rotateClockwise();
    } else {
      tableCards[boardType.valueOf()][index].rotateCounterClockwise();
    }
    return tableCards;
  }

  handleRotation = (index: number, rotationDirection: RotationDirection, boardType: BoardType) => {
    this.setState(prevState => ({
      tableCards: this.updateCardData(index, prevState.tableCards, rotationDirection, boardType)
    }));
  }

  guess = () => {
    this.setState(prevState => this.updateGuess(prevState));
  }

  updateGuess(prevState: State) {
    // First check if each square has a guess
    for (var i = 0; i < prevState.tableCards[0].length; i++) {
      if (prevState.tableCards[0][i].isEmpty()) {
        return { guessText: "Board Square is empty", guessCount: prevState.guessCount }
      }
    }

    var wrongText = "These squares are wrong: "
    var isWrong = false
    for (var j = 0; j < prevState.tableCards[0].length; j++) {
      if (!prevState.tableCards[0][j].equals(prevState.data.cardData[j])) {
        wrongText += j + " "
        isWrong = true
      }
    }
    if (isWrong) {
      return { guessText: wrongText, guessCount: prevState.guessCount + 1 }
    }

    return { guessText: "Guessed correctly in " + (prevState.guessCount + 1) + " guesses!", guessCount: prevState.guessCount + 1 }
  }

  clueSelectChange = (index: number, boardType: BoardType) => {
    this.setState(prevState => this.updateSelectState(prevState, index, boardType));
  }

  updateSelectState(prevState: State, index: number, boardType: BoardType) {
    var updatedCards: CardData[][] = []

    var updatedClueCards: CardData[] = []
    for (var c of prevState.tableCards[1]) {
      updatedClueCards.push(c)
    }
    var updatedBoardCards: CardData[] = []
    for (var d of prevState.tableCards[0]) {
      updatedBoardCards.push(d)
    }
    updatedCards.push(updatedBoardCards)
    updatedCards.push(updatedClueCards)

    updatedCards[boardType.valueOf()][index].flipSelectState()

    var firstCardI = -1
    var firstCardJ = -1
    var breakAgain = false
    for (var i = 0; i < updatedCards.length; i++) {
      for (var j = 0; j < updatedCards[i].length; j++) {
        if (updatedCards[i][j].selected) {
          if (firstCardI === -1) {
            firstCardI = i
            firstCardJ = j
            continue
          }

          var holderCard: any = updatedCards[firstCardI][firstCardJ]
          updatedCards[firstCardI][firstCardJ] = updatedCards[i][j]
          updatedCards[i][j] = holderCard

          // Turn off all selections
          for (var cardSet of updatedCards) {
            for (var card of cardSet) {
              card.selected = false
            }
          }

          breakAgain = true
          break
        }
      }
      if (breakAgain) {
        break
      }
    }

    return {
      tableCards: [updatedCards[0], updatedCards[1]]
    }
  }

  render() {
    var guess = ""
    if (this.state.guessCount > 0) {
      guess += "Guess " + this.state.guessCount + ": "
    }
    guess += this.state.guessText
    if (this.state.guessText.includes("Guessed correctly in")) {
      guess = this.state.guessText;
    }

    return (
      <>
        <Board
          hints={this.state.data.hints}
          mode={GameMode.Solve}
          cards={this.state.tableCards[0]} getHint={(e: any) => e}
          rotate={(index: number, rotationDirection: RotationDirection) => this.handleRotation(index, rotationDirection, BoardType.Playboard)}
          changeSelect={(index: number, newState: boolean) => this.clueSelectChange(index, BoardType.Playboard)}
        />
        <ClueBoard
          cards={this.state.tableCards[1]}
          rotate={(index: number, rotationDirection: RotationDirection) => this.handleRotation(index, rotationDirection, BoardType.Clueboard)}
          changeSelect={(index: number) => this.clueSelectChange(index, BoardType.Clueboard)}
        />
        <button onClick={() => this.guess()}>Guess</button>
        {guess}
      </>
    );
  }
}
