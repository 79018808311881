import './Create.css';

// @ts-ignore: Deal with import issues later
import Board from './Board';
import { Component } from 'react';
// @ts-ignore: Deal with import issues later
import { HintData, RoundData, GameMode, Mode, CardData } from './GameData';
// @ts-ignore: Deal with import issues later
import { encode } from './Parameter';
// @ts-ignore: Deal with import issues later
import { getRandomCards } from './GameData';

interface State {
  shownURL: string;
  allCards: CardData[];
  hints: HintData;
}

interface Props {

}

export class Create extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      shownURL: "",
      allCards: getRandomCards(5),
      hints: HintData.empty(),
    };

  }

  createURL() {
    var roundData = new RoundData(Mode.CARDREF, this.state.hints, this.state.allCards)
    this.setState(() => ({
      shownURL: encode(roundData)
    }));
  }

  newHint(prevState: State, hintText: string, num: number) {
    var newHints = new HintData(prevState.hints.hints[0], prevState.hints.hints[1], prevState.hints.hints[2], prevState.hints.hints[3]);
    newHints.hints[num] = hintText;
    return newHints;
  }

  getEnteredHints = (hintText: string, num: number) => {
    this.setState(prevState => ({
      hints: this.newHint(prevState, hintText, num)
    }));
  }

  render() {
    return (
      <div>
        <Board
          cards={this.state.allCards}
          mode={GameMode.Create}
          getHint={this.getEnteredHints}
          hints={new HintData("", "", "", "")}
          changeSelect={(e: any) => e}
          rotate={(e: any) => e}
        />
        <button onClick={() => this.createURL()}>Create link</button>
        <a href={"/s?p=" + this.state.shownURL}>{this.state.shownURL}</a>
      </div>
    );
  }
}
