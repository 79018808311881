import { CardData, HintData, RoundData, Mode, CARD_LIST } from './GameData';

export function encode(roundData: RoundData) {
  var hints = roundData.hints.hints;
  var cards = roundData.cardData;

  var totalList: string[] = [];
  if (roundData.mode === Mode.ANY) {
    totalList = totalList.concat(roundData.mode.valueOf().toString())

    totalList = totalList.concat(hints);

    for (var cardAny of cards) {
      totalList = totalList.concat(cardAny.words);
    }
  } else {
    totalList = totalList.concat(roundData.mode.valueOf().toString())

    totalList = totalList.concat(hints);

    for (var cardRef of cards) {
      totalList = totalList.concat(cardRef.refId.toString());
      totalList = totalList.concat(cardRef.orientation.toString());
    }
  }

  var encodedData = btoa(totalList.toString()).toString();

  return encodedData
}

export function decode(encodedData: string) {
  var decodedData = atob(encodedData);

  var data = decodedData.split(",");

  var mode: number = Number(data[0])
  if (mode === Mode.ANY.valueOf()) {
    var hintsAny = new HintData(data[1], data[2], data[3], data[4]);
    var cardsAny = [
      new CardData(data[5], data[6], data[7], data[8]),
      new CardData(data[9], data[10], data[11], data[12]),
      new CardData(data[13], data[14], data[15], data[16]),
      new CardData(data[17], data[18], data[19], data[20]),
      new CardData(data[21], data[22], data[23], data[24]),
    ];
    return new RoundData(mode, hintsAny, cardsAny);
  }

  var hints = new HintData(data[1], data[2], data[3], data[4]);
  var cards: CardData[] = [
    CARD_LIST[+data[5]].clone(), // set orientation
    CARD_LIST[+data[7]].clone(), // set orientation
    CARD_LIST[+data[9]].clone(), // set orientation
    CARD_LIST[+data[11]].clone(), // set orientation
    CARD_LIST[+data[13]].clone(), // set orientation
  ];
  cards[0].setOrientation(+data[6])
  cards[1].setOrientation(+data[8])
  cards[2].setOrientation(+data[10])
  cards[3].setOrientation(+data[12])
  cards[4].setOrientation(+data[14])

  return new RoundData(mode, hints, cards);

}

export function getGameParam() {
  const queryParameters = new URLSearchParams(window.location.search)
  return queryParameters.get("p")!
}