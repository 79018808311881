import './Help.css';

import { Component } from "react";

export class Help extends Component {
    render() {
        return (
            <div className='help'>
                Welcome to Trifolium!
                <br></br>
                <br></br>
                Create: Enter a set of 4 words as hints that can help your friend guess the order and rotation of the 4 tiles in the puzzle. When done, create a link and share with your friends!
                <br></br>
                <br></br>
                Solve: When you get a link, place the tiles (click to select and then place) on the board that match the clues correctly.
            </div>
        );
    }
}